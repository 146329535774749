<template>
  <component :is="comp" v-bind="$attrs" v-on="$listeners" ref="tooltipEl">
    <slot name="default"></slot>
  </component>
</template>

<script>
import Tooltip from "bootstrap/js/src/tooltip";

export default {
  name: "Tooltip",
  props: {
    comp: {
      type: String,
      default: () => "span"
    },
    title: {
      type: String,
      default: () => "",
      required: true
    }
  },
  data: () => ({
    instance: null
  }),
  mounted() {
    this.instance = new Tooltip(this.$refs.tooltipEl, { title: this.title });
  },
  watch: {
    title(val) {
      this.instance.dispose();
      this.instance = new Tooltip(this.$refs.tooltipEl, { title: val });
    }
  },
  beforeDestroy() {
    this.instance.dispose();
  }
};
</script>
