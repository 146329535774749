<template>
  <component :is="tooltipComponent" ref="popper" v-bind="tooltipAttrs">
    <div
      class="avatar"
      v-lazy-container="{
        selector: 'img',
        error: '/static_images/user.svg',
        loading: '/static_images/user.svg'
      }"
      :style="`width: ${size}; padding-bottom: ${size};`"
    >
      <img :data-src="src" :alt="alt" />
      <form v-if="withChange">
        <input
          type="file"
          accept="image/*"
          ref="inputFile"
          @change="e => handleFiles(e.target.files)"
        />
        <div
          :class="{ hover: drag }"
          @dragenter.prevent="dragOn"
          @dragover.prevent="dragOn"
          @dragleave.prevent="dragOff"
          @drop.prevent="onFileDrop"
          @click="$refs.inputFile.click()"
        >
          <SvgIcon icon="camera" color="light"></SvgIcon>
        </div>
      </form>
    </div>
  </component>
</template>

<script>
import SvgIcon from "./SvgIcon";
import Tooltip from "./Tooltip";
export default {
  name: "Avatar",
  components: { Tooltip, SvgIcon },
  props: {
    src: {
      type: String
    },
    alt: {
      type: String,
      required: false
    },
    withChange: {
      type: Boolean,
      default: () => false
    },
    size: {
      type: String,
      default: () => "40px"
    }
  },
  data: () => ({
    drag: false
  }),
  computed: {
    tooltipComponent() {
      return this.withChange ? "tooltip" : "div";
    },
    tooltipAttrs() {
      return this.withChange
        ? {
            title: this.src
              ? this.$t("user.texts.changeAvatar")
              : this.$t("user.texts.setAvatar")
          }
        : {};
    }
  },
  methods: {
    handleFiles(files) {
      if (files[0]) this.$emit("upload", files[0]);
    },
    onFileDrop(e) {
      let dt = e.dataTransfer;
      let files = dt.files;
      this.handleFiles(files);
      this.dragOff();
    },
    dragOff() {
      this.drag = false;
    },
    dragOn() {
      this.drag = true;
    }
  },
  mounted() {
    if (!this.src)
      setTimeout(
        () =>
          this.$refs.popper.instance ? this.$refs.popper.instance.show() : null,
        1500
      );
  }
};
</script>

<style lang="scss">
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  height: 0 !important;
  width: 100%;
  padding-bottom: 100%;

  & > img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }

  & > form {
    input {
      display: none;
    }

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: all 0.3s;
      cursor: pointer;

      @include reg-backdrop-light-blur;

      opacity: 0;

      &:hover,
      &.hover {
        opacity: 1;
      }

      & > svg {
        height: 25%;
        filter: drop-shadow(0 0 10px theme-color("dark"));
      }
    }
  }
}
</style>
